<template>
  <Survey />
</template>

<script>
import Survey from '@/components/Survey.vue'

export default {
  name: 'Home',
  components: {
    Survey
  }
}
</script>

<style scoped>
  .home {
    padding: 0 0 2vh 0;
  }
</style>