<template>
  <div class="container v-application theme--light">
    <div v-if="loading" class="loading">...Loading</div>
    <survey v-if="!loading" :survey="survey"></survey>
  </div>
</template>

<script>
// import * as SurveyVue from "survey-vue";
import * as SurveyVue from "survey-core";
import { Survey } from "survey-vue-ui";
import axios from "axios";
import heic2any from "heic2any";
import reduce from "image-blob-reduce";
import ExifReader from "exifreader";
// import * as widgets from "surveyjs-widgets";
// import { init as customWidget } from "../components/customwidget";

import { bootstrapThemeName } from "survey-core/plugins/bootstrap-integration";
import "bootstrap/dist/css/bootstrap.css";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// const Survey = SurveyVue.Survey;
// Survey.cssType = "bootstrap";
SurveyVue.StylesManager.applyTheme(bootstrapThemeName);

const reducer = reduce();
reducer._create_blob = function (env) {
  return this.pica
    .toBlob(env.out_canvas, "image/jpeg", 0.8)
    .then(function (blob) {
      env.out_blob = blob;
      return env;
    });
};

// widgets.icheck(SurveyVue);
// widgets.select2(SurveyVue);
// widgets.inputmask(SurveyVue);
// widgets.jquerybarrating(SurveyVue);
// widgets.jqueryuidatepicker(SurveyVue);
// widgets.nouislider(SurveyVue);
// widgets.select2tagbox(SurveyVue);
// widgets.sortablejs(SurveyVue);
// widgets.ckeditor(SurveyVue);
// widgets.autocomplete(SurveyVue);
// widgets.bootstrapslider(SurveyVue);
// customWidget(SurveyVue);

SurveyVue.Serializer.addProperty("question", "tag:number");

export default {
  components: {
    Survey,
  },
  created() {
    this.get();
  },
  data() {
    var json = { pages: [{ name: "page1" }] };
    var model = new SurveyVue.Model(json);
    return {
      survey: model,
      loading: false,
      files: {},
      id: "",
      cssEl: null,
      jsEl: null,
      form: {},
    };
  },
  methods: {
    latestHandler(id, options) {
      return api
        .request({
          method: "GET",
          url: `/api/forms/survey/latest/${id}`,
          ...options,
        })
        .then((res) => res.data);
    },
    uploadHandler(id, options) {
      return api
        .request({
          method: "POST",
          url: `/api/files/upload/survey/${id}`,
          ...options,
        })
        .then((res) => res.data);
    },
    getHandler(id, options) {
      return api
        .request({
          method: "GET",
          url: `/api/forms/survey/${id}`,
          ...options,
        })
        .then((res) => res.data);
    },
    createHandler(options) {
      return api
        .request({
          method: "POST",
          url: `/api/surveys`,
          ...options,
        })
        .then((res) => res.data);
    },
    updateHandler(id, options) {
      return api
        .request({
          method: "PUT",
          url: `/api/surveys/${id}`,
          ...options,
        })
        .then((res) => res.data);
    },
    async get() {
      this.getLocation();
      this.loading = true;

      let fetch;
      const options = {
        headers: this.setHeadersSaveSurvey(),
      };

      if (this.$route.params.appId) {
        fetch = this.latestHandler(this.$route.params.appId, options);
      } else {
        fetch = this.getHandler(this.$route.params.id, options);
      }

      fetch
        .then((res) => {
          this.loading = false;

          const data = {
            ...res.source,
            ...res.value,
          };
          for (let k in res.files) {
            const items = res.files[k];

            items.map((e, i) => {
              data[k][i].content = e.uri;
              return e;
            });
          }

          this.id = res.id;
          this.files = res.files || {};
          this.form = res.form || {};
          this.survey = this.setSurveyModel(res.form, data);
          this.setCustomStyle((res.form && res.form.css) || "");
          this.setCustomJs((res.form && res.form.js) || "");
          return res;
        })
        .catch((err) => {
          this.loading = false;
          console.error("get survey err: ", err);
          return err;
        });
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
    setSurveyModel(res, data) {
      const json = res.json || {};
      const model = new SurveyVue.Model(json);
      const headers = this.setHeadersSaveSurvey();

      // Set default value
      model.data = data;

      model.onAfterRenderQuestion.add((survey, options) => {
        const acceptTypes =
          options && options.question && options.question.acceptedTypes;
        const os = this.getMobileOperatingSystem();
        const input = options.htmlElement.querySelector("input[type='file']");
        if (
          input &&
          acceptTypes &&
          (acceptTypes.includes("image") || acceptTypes.includes(".heic")) &&
          os === "Android"
        ) {
          input.setAttribute("capture", true);
        }
      });

      // Upload
      model.onUploadFiles.add(async (survey, options) => {
        let uploads = (this.files && this.files[options.name]) || [];
        const files = await Promise.all(
          options.files.map(async (file) => {
            let exif;
            const fileName = file.name;

            if (file.type.includes("image")) {
              exif = await this.getExif(file).catch((err) =>
                console.error("getExif: ", err)
              );
              file = await this.heic2jpeg(file).catch((err) =>
                console.error("heic2jpeg: ", err)
              );
              file = await this.resizeFile(file).catch((err) =>
                console.error("resizeFile: ", err)
              );
            }

            // const content = URL.createObjectURL(file);
            const up = await this.uploadFile(file).catch((err) =>
              console.error("uploadFile: ", err)
            );
            uploads.push({
              ...up,
              exif,
              fileName,
            });

            const fdata = {
              file,
              name: file.name,
              content: up && up.uri,
              exif,
            };

            return fdata;
          })
        );
        this.files[options.name] = uploads;

        options.callback("success", files);
      });

      model.onClearFiles.add((response, options) => {
        if (options.fileName) {
          this.files[options.name] = this.files[options.name].filter((e) => {
            return e.fileName != options.fileName;
          }, []);
        } else {
          this.files[options.name] = undefined;
        }

        options.callback("success", undefined);
      });

      // Save
      model.onComplete.add(async (survey) => {
        this.loading = true;
        const location = await this.getLocation().catch((err) =>
          console.error(err)
        );
        const questions = survey.getAllQuestions();
        let sdata = survey.data;
        for (var i = 0; i < questions.length; i++) {
          const key = questions[i].getValueName();
          const cjson = questions[i].getConditionJson();

          if (cjson && cjson.items) {
            cjson.items.map((e) => {
              if (!sdata[key]) {
                sdata[key] = {};
              }
              if (!sdata[key][e.name]) {
                sdata[key][e.name] = "";
              }
            });
          }
        }

        const data = {
          formId: this.form.id,
          answer: sdata,
          coordinates: location && location.coordinates,
          files: this.files,
        };

        this.save(headers, data)
          .then((res) => {
            this.loading = false;
            this.reponseResult("success", this.form && this.form.success);

            // Call onSuccess
            if (window.onSuccess) {
              window.onSuccess();
            }

            setTimeout(() => {
              window.close();
            }, 3000);
            return res;
          })
          .catch((err) => {
            this.loading = false;
            this.reponseResult("error", this.form && this.form.error);

            // Call onSuccess
            if (window.onError) {
              window.onError();
            }

            console.error("save survey err: ", err);
            return err;
          });
      });

      return model;
    },
    reponseResult(status, text) {
      const tag = document.querySelector("div.v-application--wrap");
      if (!text) {
        if (status === "success") {
          text = "Thank you for completing the survey.";
        } else {
          text = "An error occurred and we could not save the results.";
        }
      }

      if (tag && tag.innerHTML) {
        tag.innerHTML = `<div class="survey-result survey-${status}">${text}</div>`;
      }
    },
    setCustomStyle(css) {
      this.cssEl && this.cssEl.remove();
      this.cssEl = document.createElement("style");
      this.cssEl.type = "text/css";
      this.cssEl.innerHTML = css;

      document.getElementsByTagName("head")[0].appendChild(this.cssEl);
    },
    setCustomJs(js) {
      this.jsEl && this.jsEl.remove();
      this.jsEl = document.createElement("script");
      this.jsEl.type = "text/javascript";
      this.jsEl.innerHTML = js;

      document.getElementsByTagName("head")[0].appendChild(this.jsEl);
    },
    resizeFile(file) {
      return reducer.toBlob(file, { max: 1000 });
    },
    uploadFile(file) {
      const data = new FormData();
      data.append("file", file);

      const options = { data };
      return this.uploadHandler(this.form.id, options);
    },
    setHeadersSaveSurvey() {
      if (this.$route.query.token) {
        return {
          "X-Survey-Token": this.$route.query.token,
        };
      }

      return {};
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        if (navigator && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              let coordinates = [];
              if (position.coords.latitude && position.coords.longitude) {
                coordinates = [
                  position.coords.latitude,
                  position.coords.longitude,
                ];
              }
              const location = position.coords;
              location.coordinates = coordinates;

              resolve(location);
            },
            (err) => {
              reject(err);
            },
            options
          );
        } else {
          resolve();
        }
      });
    },
    getExif(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            var tags = ExifReader.load(e.target.result);

            // The MakerNote tag can be really large. Remove it to lower
            // memory usage if you're parsing a lot of files and saving the
            // tags.
            delete tags["MakerNote"];

            // Use the tags now present in `tags`.
            resolve({
              Make: tags["Make"] && tags["Make"].description,
              Model: tags["Model"] && tags["Model"].description,
              DateTimeOriginal:
                tags["DateTimeOriginal"] &&
                tags["DateTimeOriginal"].description,
              GPSLatitude:
                tags["GPSLatitude"] && tags["GPSLatitude"].description,
              GPSLongitude:
                tags["GPSLongitude"] && tags["GPSLongitude"].description,
              GPSSpeed: tags["GPSSpeed"] && tags["GPSSpeed"].description,
              GPSSpeedRef:
                tags["GPSSpeedRef"] && tags["GPSSpeedRef"].description,
              Software: tags["Software"] && tags["Software"].description,
              Height: tags["Image Height"] && tags["Image Height"].value,
              Width: tags["Image Width"] && tags["Image Width"].value,
            });
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async heic2jpeg(file) {
      if (file.type.includes("heic")) {
        const blob = new Blob([file], { type: file.type });

        return heic2any({
          blob,
          toType: "image/jpeg",
          quality: 0.8,
        });
      }

      return file;
    },
    save(headers, data) {
      if (this.id) {
        return this.update(headers, data);
      } else {
        return this.create(headers, data);
      }
    },
    create(headers, data) {
      const options = {
        data,
        headers,
      };
      return this.createHandler(options);
    },
    update(headers, data) {
      const options = {
        data,
        headers,
      };
      return this.updateHandler(this.id, options);
    },
  },
};
</script>

<style>
.container {
  min-height: 100%;
  padding-bottom: 3vh;
}

.loading {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.sv_row {
  min-width: unset !important;
}

.sv_qstn {
  min-width: unset !important;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: unset !important;
  outline: none !important;
}

.panel-body {
  border: 1px solid #ddd;
}

.sv_q_file div:nth-child(4) {
  display: flex;
  flex-wrap: wrap;
}

.sv_q_file div:nth-child(4) span {
  margin-right: 10px;
}

.sv_q_file div:nth-child(4) img {
  height: 200px;
}

.sv-action-bar {
  padding: inherit !important;
}

.panel-footer {
  background-color: transparent !important;
}

.btn {
  border: 1px solid #ddd !important;
}

.survey-result {
  text-align: center;
  padding: 5%;
  font-size: 3vw;
}

.survey-success {
  color: green;
}

.survey-error {
  color: red;
}

.sv_main {
  margin-top: 3vh;
}

.sv_main .sv_header__text {
  max-width: none !important;
}

.sv_body_empty {
  margin-top: 3vh;
}

@media only screen and (max-width: 1000px) {
  .survey-result {
    font-size: 5vw;
  }
}

@media (max-width: 600px) {
  .sv_main
    .sv_container
    .panel-body.card-block
    .sv_row
    .sv_qstn
    table.table
    td {
    margin: 5px 0;
  }
}
</style>
